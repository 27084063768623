<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal" editType="standard">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        {{ getTitleText }}
      </h2>
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <AppInput
          type="richselect"
          rules="required"
          :name="
            $t(
              'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.title.type'
            )
          "
          :label="
            $t(
              'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.title.type'
            )
          "
          :placeholder="`Select one`"
          :options="messageTypes"
          :disabled="isEditing"
          hide-search-box
          v-model="form.hardware_type"
        />
        <AppInput
          v-model="form.message_text"
          type="text"
          rules="required"
          :name="
            $t(
              'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.title.message'
            )
          "
          :label="
            $t(
              'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.title.message'
            )
          "
        />

        <div class="flex justify-end items-center mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">{{
              $t('components.stepNavigation.cancel')
            }}</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" class="ml-3">{{
            isEditing
              ? $t('components.stepNavigation.update')
              : $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { HardwareMessageConfig } from '@/config/HardwareMessageConfig'

export default {
  name: 'AddEditHardwareMessage',
  props: {
    messageTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    SlideOverRightTrigger: () =>
      import('@/components/modals/SlideOverRightTrigger'),
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: HardwareMessageConfig.events.sorId,
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    isEditing: function() {
      return Object.keys(this.item).length !== 0
    },
    getTitleText() {
      return this.isEditing
        ? this.$t(
            'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.headline.edit'
          )
        : this.$t(
            'components.settingsManagement.riderApp.additionalHardwareMessages.addEdit.headline.add'
          )
    },
  },
  mounted() {
    EventBus.$on(HardwareMessageConfig.events.editingData, (item) => {
      this.item = item
      this.form = { ...this.item }
    })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(HardwareMessageConfig.events.sorToggle))
    },
    submit: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? HardwareMessageConfig.api.update(this.orgId, this.item.id)
        : HardwareMessageConfig.api.create(this.orgId)

      const formDataProxy = { ...this.form }
      if (this.isEditing) {
        delete formDataProxy.id
        delete formDataProxy.organization
        delete formDataProxy.hardware_type
      }

      let data = new xMan(formDataProxy).toFormData()
      let message = this.isEditing
        ? 'Message updated successfully'
        : 'Message added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(HardwareMessageConfig.events.sorClose))
        // Refetch the indexData
        dispatchEvent(new Event(HardwareMessageConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        console.log('hardware-message-add-edit-err', { error })

        this.isLoading = false

        var errorData = Object.values(error.response.data)
        console.log(errorData)

        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: `Failed [${error.response.status}]`,
            text:
              error.response.status >= 500
                ? 'Failed to delete'
                : errorData[0][0],
          },
          3000
        )
      }
    },
  },
}
</script>

<style></style>
